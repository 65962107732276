.chart{
    flex: 4;
    box-shadow: 1px 1px 5px rgba(0,0,0,.1);
    padding: 5px;
    border-radius: 5px;
    .title{
        margin-bottom: 10px;
        color: gray;
        font-weight: 500;
    }
}