.actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .viewButton{
        // border: .5px solid lightblue;
        // padding: 0px 1px;
        border-radius: 5px;
        background: #FFF;
        color: rgb(161, 209, 236);
        cursor: pointer;
        // max-height: 30px;
        text-align: center;
    }
}