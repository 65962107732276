.widget{
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    // box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.47);
    box-shadow: 1px 1px 5px rgba(0,0,0,.1);
    border-radius: 10px;
    height: 100px;
    .left, .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title{
            font-size: 14px;
            font-weight: bold;
            color: gray;
        }
        .counter{
            font-size: 28px;
            font-weight: 300;
        }
        .link{
            cursor: pointer;
            border-bottom: 1px solid gray;
            font-size: 12px;
        }
        .percentage{
            font-size: 14px;
            display: flex;
            align-items: center;
            &.positive{
                color: green;
            }
        }
        .icon{
            align-self: flex-end;
            // background: rgb(153, 104, 127);
            border-radius: 5px;
            // color: #FFF;
            font-size: 25px;
            padding: 5px;
        }
    }
   
}