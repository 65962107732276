 .home{
    display: flex;
    .wrapper{
        display: flex;
        flex-direction: column;
        flex: 6;
        .homecontainer{
            height: 100%;
            padding: 20px;
            .widgets, .charts{
                display: flex;
                align-items: center;
                // justify-content: space-between;
                gap: 20px;
             }
             .charts{
                padding: 20px 0;
             }
             .listTables{
                .listTitle{
                    font-size: 16px;
                    font-weight: 600;
                    color: gray;
                }
             }
             
        }
    }
 }