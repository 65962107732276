.sidebar{
    display: flex;
    flex-direction: column;
    // min-height: 100vh;
    padding: 10px;
    border-right: 0.5px solid rgb(245, 242, 242);
    box-shadow: 0 2px 1px rgba(0,0,0,.1);
    background: #00846B!important;
    min-height: 100vh;

    hr{
        width: 100%;
        border: 0.5px solid rgb(245, 242, 242);
    }
    .top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 20px;
        height: 40px;
        .logo{
        font-size: 20px;
        font-weight: bold;
        color: #013B70;
    }
    }
    .center{
        padding-left: 10px;
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            .title{
                font-size: 12px;
                font-weight: bold;
                color: #999;
                margin-top: 15px;
                margin-bottom: 5px;
            }
            li{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 3px 5px;
                cursor: pointer;
                font-size: 20px;
                &:hover{
                    cursor: pointer;
                    background: #999;
                    color: #FFF;
                }
                span{
                    font-size: 16px;
                    margin-left: 5px;
                    color: #333;
                }
                
            }
            .link{
                list-style: none;
                text-decoration: none;
                font-size: 16px;
                padding: 0 5px;
                color: gray;
                font-weight: 500;
                color: #FFF!important;
                opacity: .9;
            }
            .icon{
                font-size: 14px;
                color: #013B70;
            }
        }
    }
    .bottom{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .colorOption{
            background: #333;
            height: 20px;
            width: 20px;
            border-radius: 5px;
            margin: 5px;
            &:nth-child(1){
                background: red;
            }
        }
    }
}