.navbar{
    display: flex;
    height: 60px;
    border-bottom: 0.5px solid rgb(245, 242, 242);
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    color: gray;
    font-style: 14px;
    .search{
        display: flex;
        align-items: center;
        border-radius: 5px;
        border: 0.5px solid rgb(245, 242, 242);
        input{
            padding: 10px;
            background: transparent;
            border: 0.5px solid #555;
            border: none;
            outline: none;
            &::placeholder{
                font-size: 14px;
                color: lightgray;
            }
        }
    }
    .items{
        display: flex;
        align-items: center;
        .item{
            display: flex;
            align-items: center;
            margin-right: 20px;
            position: relative;
        }
        .icon{
            font-size: 20px;
        }
        .counter{
            width: 15px;
            height: 15px;
            background: red;
            color: white;
            position: absolute;
            border-radius: 50%;
            text-align: center;
            top: -5px;
            left: -5px;
            font-size: 10px;
            font-weight: bold;
        }
    }
}