.loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid white;
    border-right: 4px solid white;
    border-bottom: 4px solid white;
    border-left: 4px solid pink;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /* Second Loader Goes Here */