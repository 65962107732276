.feature{
    flex: 2;
    box-shadow: 1px 1px 5px rgba(0,0,0,.1);
    padding: 5px;
    border-radius: 5px; 
    .top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
            font-weight: 500;
            color: gray;
            font-size: 16px;
        }
    }
    .bottom{
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:10px;
        .featuredChart{
            width: 100px;
            height: 100px;
        }
        .title{
            font-weight: 500;
            color: gray;
        }
        .amount{
            font-size: 30px;
        }
        .description{
            font-weight: 300;
            font-size: 12px;
            color: gray;
            text-align: center;
        }
    }
}